<template>
    <div class="px-3 py-2">
        <v-form
            ref="forcar"
            lazy-validation
        >
            <b-sidebar id="addcard_calendar_date" lazy aria-labelledby="sidebar-no-header-title" no-header backdrop width="800px" style="direction:ltr" right title="إضافة ملاحظة" shadow >
                <template #default="{ hide }">
                    <div class="m-1 text-start" style="direction:rtl">
                        <div style="width:140px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                            <span>{{lang.add_maint_request}}</span>
                        </div>
                        <div @click="hide" id="hidecardCalendarDated" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                            <span>{{lang.close}}</span>
                        </div>
                    </div>
                    <v-divider></v-divider>
                    <v-row style="margin:auto 5px; direction:rtl">
                        <v-col cols="12" style="direction:rtl">
                            <label>{{lang.vat_type}}</label>
                            <b-input-group>
                                <b-form-select class="selborder"
                                    v-model="vattype"
                                    :options="vattypes"
                                    />
                                <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                                    <i class="fas fa-arrow-down"></i>
                                </b-input-group-append>
                            </b-input-group>
                        </v-col>
                        <v-col cols="12" style="direction:rtl">
                            <v-simple-table>
                                <thead>
                                    <th class="text-center backBlack">{{ lang.work_type }}</th>
                                    <th class="text-center backBlack" style="width:130px;">{{lang.work_name}}</th>
                                    <th class="text-center backBlack" style="width:100px;">{{lang.price}}</th>
                                    <th class="text-center backBlack" style="width:80px;">{{lang.name}}</th>
                                </thead>
                                <tbody>
                                    <tr v-for="(item,index) in descriptions" :key="index">
                                        <td class="text-start">{{item.description}}</td>
                                        <td class="text-center">{{getUserName(item.userid)}}</td>
                                        <td class="text-center">{{item.price}}</td>
                                        <td class="text-center">
                                            <b-button type="button" variant="danger" style="padding:0.15rem 0.5rem !important;font-weight:bolder;width:80px;background:red;border:none" @click="removeItem(index)">{{lang.delete}}</b-button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-start">
                                            <b-form-input class="inborder" v-model="newRow.description" />
                                        </td>
                                        <td class="text-center">
                                            <b-input-group>
                                                <b-form-select class="selborder"
                                                    v-model="newRow.userid"
                                                    :options="userslist"
                                                    />
                                                <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                                                    <i class="fas fa-arrow-down"></i>
                                                </b-input-group-append>
                                            </b-input-group>
                                        </td>
                                        <td class="text-center">
                                            <b-form-input class="inborder" v-model="newRow.price" />
                                        </td>
                                        <td class="text-center">
                                            <b-button type="button" class="btn-sm" variant="success" style="width:80px;font-weight:bolder;background:green;border:none;" @click="addNewRow()">{{lang.add}}</b-button>
                                        </td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-col>
                        <v-col cols="12" style="direction:rtl">
                            <v-simple-table>
                                <thead>
                                    <th class="text-center backBlack">{{lang.terms}}</th>
                                    <th class="text-center backBlack" style="width:80px;">{{lang.delete}}</th>
                                </thead>
                                <tbody>
                                    <tr v-for="(item,index) in conditions" :key="index">
                                        <td class="text-start">{{item.description}}</td>
                                        <td class="text-center">
                                            <b-button type="button" variant="danger" style="padding:0.15rem 0.5rem !important;font-weight:bolder;width:80px;background:red;border:none" @click="removecon(index)">{{lang.delte}}</b-button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-start">
                                            <b-form-input class="inborder" v-model="newCondition.description" />
                                        </td>
                                        
                                        <td class="text-center">
                                            <b-button type="button" class="btn-sm" variant="success" style="width:80px;font-weight:bolder;background:green;border:none;" @click="addNewCond()">{{lang.add}}</b-button>
                                        </td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </template>
                <template #footer="">
                    <div class="d-flex text-light align-items-center px-3 py-2">
                    <b-button type="button" variant="success" @click="addQuotation()" class="ma-2" style="width:100px;">حفظ</b-button>
                    </div>
                </template>
            </b-sidebar>
            
        </v-form>
    </div>
</template>

<script>
import axios from 'axios'
import {SnotifyPosition} from 'vue-snotify'
export default {
    data(){
        return {
            quotid: 0,
            userid: 0,
            userslist: [],
            calid: 0,
            vattype: 1,
            plate_number: '',
            descriptions: [],
            newRow: {
                description: '',
                price: '',
                userid: ''
            },
            customerid: '',
            mobile: '',
            conditions: [],
            newCondition: {
                description: '',
            },
        }
    },
    computed: {
        lang: function(){
          if(this.$cookies.get(this.$store.state.LangCooki) != null){
              if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
              return this.$store.state.lang.en;
              }else{
              return this.$store.state.lang.ar;
              }
          }
          else if(this.$store.state.deflang == 'en'){
              return this.$store.state.lang.en;
          }else{
              return this.$store.state.lang.ar;
          }
        },
        vattypes: function(){
            return [
                {text: this.lang.price_inc_vat,value: 2},
                {text: this.lang.prices_exclusive_vat,value: 1},
                {text: this.lang.price_without_vat,value:3},
            ]
        }
    },
    created(){
        this.getUsers();
    },
    methods:{
        async getQuot(){
            if(this.quotid == 0){
                return false;
            }
            const post = new FormData();
            post.append("type","getQuot")
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[id]',this.quotid)
            const response = await axios.post(this.$store.state.SAMCOTEC.r_path,post);
            if(response && response.data && response.data.results){
                const res = response.data.results.data;
                
                for(let i = 0; i < res.items.length;i++){
                    this.descriptions.push({
                        description: res.items[i].description,
                        price: res.items[i].price,
                        userid: this.userid
                    })
                }

                for(let i = 0; i < res.cond.length;i++){
                    this.conditions.push({
                        description: res.cond[i].description,
                    })
                }
            }
        },
        removeItem(index){
            this.descriptions.splice(index, 1);
            
        },
        removecon(index){
            this.conditions.splice(index, 1);

        },
        getUserName(userid){
            for(let i=0;i<this.userslist.length;i++){
                if(this.userslist[i].value == userid){
                    return this.userslist[i].text
                }
            }
        },
        addNewCond(){
            if(this.newCondition.description == ''){
                let message = 'نص الشرط مطلوب دائما ولا يمكن ان يكون فارغا';
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            this.conditions.push({
                description: this.newCondition.description,
            });
            this.newCondition.description = ''
        },
        addNewRow(){
            if(this.newRow.description == '' || this.newRow.price == ''){
                let message = 'وصف العمل والسعر يجب ان لا تكون فارغة';
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            this.descriptions.push({
                description: this.newRow.description,
                price: this.newRow.price,
                userid: this.newRow.userid
            });
            this.newRow.description = ''
            this.newRow.price = ''
        },
        async getUsers(){
            const post = new FormData();
            post.append("type" , "getEmpsS");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[status]',1);
            const response = await axios.post(this.$store.state.SAMCOTEC.r_path,post);
            if(response && response.data && response.data.results && response.data.results.data && response.data.results.data.results){
                const res = response.data.results.data.results;
                console.log("asdfas",res);
                for(let i = 0;i < res.length;i++){
                    this.userslist.push({text: res[i].employee_name, value: res[i].id})
                }
            }
        },
        async addQuotation(){
            if(this.descriptions.length == 0){
                let message = 'يجب اضافة وصف عمل واحدعلى الاقل لعرض السعر';
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            const post = new FormData();
            post.append("type","addCalCard")
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[id]',this.calid)
            post.append("data[vattype]",this.vattype)
            post.append("data[plate_number]",this.plate_number)
            post.append("data[customerid]",this.customerid)
            post.append("data[mobile]",this.mobile)
            
            for(let i=0;i<this.descriptions.length;i++){
                post.append('data[descr]['+i+'][description]',this.descriptions[i].description)
                post.append('data[descr]['+i+'][price]',this.descriptions[i].price)
                post.append('data[descr]['+i+'][userid]',this.descriptions[i].userid)
            }

            for(let i=0;i<this.conditions.length;i++){
                post.append('data[conditions]['+i+']',this.conditions[i].description)
            }
            
            const response = await axios.post(this.$store.state.SAMCOTEC.r_path,post);
            if(response && response.data && response.data.results){
                console.log(response.data.results);
                let message = 'تمت اضافة امر التشغيل بنجاح';
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                window.open('../api/print.php?cardid='+response.data.results.data.newID,'_blank');
                // this.$parent.$parent.$parent.getCurrentCards();
                // document.getElementById('hidecardCalendarDated').click();
            }
        },

        getStatus(status){
            const post = new FormData();
            post.append("type" , 'getStaticOptions');
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append('data[phrase]',status);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data.results.data;
                    for(let i=0;i<res.length;i++){
                        this.statusList.push({text: res[i][`name_`+this.lang.langname], value: res[i].value})
                    }
                }
            )
        },
        async getCurrentCards(){
           
            const post = new FormData();
            let cook = this.$cookies.get(this.$store.state.COOKIEPhase);
            post.append('type',"getCalendar");
            post.append('auth',cook)
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki))
            post.append('data[id]',this.calid)
            const response = await axios.post(this.$store.state.SAMCOTEC.r_path,post)
            console.log(response.data.results.data)
            if(response && response.data && response.data.results && response.data.results.data){
                const res = response.data.results.data;
                this.calendarData = res;
                this.userid = this.calendarData.rows[0].userid;
                this.status = this.calendarData.rows[0].status;
                const d = new Date(this.calendarData.rows[0].start_date)
                
                this.sd.day = d.getDate() ;
                this.sd.month = d.getMonth() + 1;
                this.sd.year = d.getFullYear();
                const y = new Date(this.calendarData.rows[0].deadline)
               
                this.ed.day = y.getDate() ;
                this.ed.month = y.getMonth() + 1;
                this.ed.year = y.getFullYear();
            }
        }
    }
}
</script>

<style>
.rowDiv{
    width:48%;
    margin-inline-end: 1%;
    padding:5px;
}
.rowDivFull{
    width:98%;
    margin-inline-end: 2%;
    margin-inline-start: 1%;
    padding:5px;
}
.myBtn{
    flex-wrap: wrap;
    display:flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: stretch;
    margin-top:5px;
}
.mySubBtn{
    flex-wrap: wrap;
    display:none;
    flex-direction: row;
    justify-content: start;
    justify-items: center;
    align-items: center;
    margin-top:5px;
}
.btnRow{
    min-width:100px;
    padding:5px 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
    margin-inline-end:5px;
    border:2px solid #fff;
    cursor: pointer;
    font-weight: bolder;
    overflow-anchor: none;
    border-radius: 5px;
    margin-top:10px;
    margin-bottom:10px;
    margin-inline-end:10px !important;
}
.btnRowF{
    min-width:120px;
    padding:5px 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
    margin-inline-end:5px;
    border:2px solid #fff;
    cursor: pointer;
    overflow-anchor: none;
    border-radius: 5px;
    margin-top:10px;
    margin-bottom:10px;
}
.c_blue{
    background:blue !important;
    color:#fff !important;
}
.c_darkblue{
    background:darkblue !important;
    color:#fff !important;
}
.c_green{
    background:green !important;
    color:#fff !important;
}
.c_red{
    background:red !important;
    color:#fff !important;
}
.c_darkslategrey{
    background:darkslategrey !important;
    color:#fff !important;
}
.c_darkgreen{
    background:darkgreen !important;
    color:#fff !important;
}
.c_darkcyan{
    background:darkcyan !important;
    color:#fff !important;
}
.c_darkolivegreen{
    background:darkolivegreen !important;
    color:#fff !important;
}
.c_darkslateblue{
    background:darkslateblue !important;
    color:#fff !important;
}
.c_darkorange{
    background:darkorange !important;
    color:#fff !important;
}
.c_darkorchid{
    background:darkorchid !important;
    color:#fff !important;
}
.c_lightgray{
    background:#d1d1d1 !important;
    color:#000 !important;
}
.c_black{
    background:#000 !important;
    color:#fff !important;
}
.c_lightgray i{
    display:none;
}
</style>