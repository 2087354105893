<template>

      <b-modal
        id="change_status"
        ref="modal2"
        :title="`${$parent.lang.status}`"
        ok-only
        @ok="changeIt()"
        :ok-title="$parent.lang.save"
      >
        <template #modal-header >
            <div class="w-100 flexit" style="direction:rtl;text-align:center">
                <div style="margin-bottom:5px!important;font-size:1rem;border:2px solid #fff !important;width:170px;display:flex;justify-content: center;justify-items: center;align-items: center;">
                    {{$parent.lang.booking_procedure}}
                </div>
            </div>
        </template>
        <div class="px-3 py-2" style="direction:rtl">
            <label>{{ $parent.lang.status }}</label>
            <b-form-select class="selborder"
                v-model="status"
                :options="statusLists" 
            />
        </div>
        <div class="px-3 py-2" style="direction:rtl">
            <label>{{$parent.lang.notes}}</label>
            <b-form-textarea class="inborder"
                v-model="notes"
            />
        </div>
        <div class="px-3 py-2" style="direction:rtl">
            <label>{{$parent.lang.change_booking_date}}</label>
            <b-form-select class="selborder"
                v-model="dialyTo"
                :options="daysList" 
            />
        </div>
        <template #modal-footer="{ close }" >
            <div class="w-100" style="direction:rtl;text-align:center;">
                <b-button type="button" @click='changeStatus()' style="font-size:1.1rem;border:2px solid #fff !important;background:green !important;width:120px;color:#fff;" class="mytab2 btn btn-sm btn-success ms-2">{{ $parent.lang.save }}</b-button>
                <b-button type="button" @click='close()' style="font-size:1.1rem;border:2px solid #fff !important;background:red !important;width:120px;color:#fff;" class="mytab2 btn btn-sm btn-success ms-2">{{ $parent.lang.close }}</b-button>
            </div>
        </template>
      </b-modal>

  </template>
  
  <script>
  import axios from 'axios'
    export default {
      data() {
        return {
          customerid: '',
          statusList: [],
          status: 1,
          dialyTo: 24,
          notes: ''
        }
      },
      created(){
        this.getStatus('Calendar');
      },
      computed:{
        statusLists: function(){
            return this.statusList;
        },
        daysList: function(){
            return [
                {text: '24 ساعة',value: 24},
                {text: '48 ساعة',value: 48},
                {text: '72 ساعة',value: 72},
                {text: '96 ساعة',value: 69}
            ]
        }
      },
      methods: {
        getStatus(status){
            const post = new FormData();
            post.append("type" , 'getStaticOptions');
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append('data[phrase]',status);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data.results.data;
                    console.log("statusList",res);
                    for(let i=0;i<res.length;i++){
                        this.statusList.push({text: res[i][`name_`+this.$parent.lang.langname], value: res[i].value})
                    }
                    console.log("statusList",res);
                }
            )
        },
        checkFormValidity() {
        //   const valid = this.$refs.form.checkValidity()
        //   this.nameState = valid
        //   return valid
        },
        resetModal() {
        //   this.name = ''
        //   this.nameState = null
        },
        handleOk3(bvModalEvent) {
          // Prevent modal from closing
        //   bvModalEvent.preventDefault()
          // Trigger submit handler
        //   this.handleSubmit()
        },
        handleSubmit() {
          // Exit when the form isn't valid
        //   if (!this.checkFormValidity()) {
        //     return
        //   }
          // Push the name to submitted names
        //   this.submittedNames.push(this.name)
          // Hide the modal manually
        //   this.$nextTick(() => {
        //     this.$bvModal.hide('modal-prevent-closing')
        //   })
        }
      }
    }
  </script>